import React, {useEffect, useState} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThLarge, faArrowLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import i18n from '../../../i18n'

const StaffNav = ( {setShowNavbar,showNavbar,setOpenNavbar}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(state => state.user.userData)
    const loading = useSelector(state => state.lessons.loading)
    const dispatch = useDispatch()

    const responsiveOne = useSelector((state)=>state.navigations.responsiveOne)

    const handleResponsiveNavbar=()=>{
      if(responsiveOne ){
        setShowNavbar(!showNavbar)
        dispatch(setOpenNavbar(!showNavbar))
      }

    }

    return (
        <div className="nav-list">
                <Link className={`nav-el`} to={'/trainings'}>
                    <div onClick={()=>handleResponsiveNavbar()} className={`icon ${(location.pathname == '/trainings') && 'active'}`}>
                        <span className="nav-icon students"></span>
                        {i18n.t('navigation.admin.trainings')}
                    </div>
                </Link>

                <Link className="nav-el" to={'/lessons'}>
                    <div onClick={()=>handleResponsiveNavbar()} className={`icon ${(location.pathname == '/lessons' || location.pathname == '/lesson_transitions' || location.pathname == '/atas') && 'active'}`}>
                        <span className="nav-icon planning"></span>
                        {i18n.t('navigation.admin.lessons')}
                    </div>
                </Link>

                    {/* sublink */}
                    { (location.pathname == '/lessons' || location.pathname == "/lesson_transitions" || location.pathname == "/atas") &&
                        <div className="sublink-bloc">
                            <Link className="nav-el" to={'/lessons'}>
                                <div onClick={()=>handleResponsiveNavbar()} className={`icon ${location.pathname == '/lessons' ? 'active' : ''}`}>
                                    {i18n.t('navigation.admin.list')}
                                </div>
                            </Link>

                            <Link className="nav-el" to={'/atas'}>
                                <div onClick={()=>handleResponsiveNavbar()} className={`icon ${location.pathname == '/atas' ? 'active' : ''}`}>
                                    {i18n.t('navigation.admin.atas')}
                                </div>
                            </Link>
                        </div>
                    }

                <Link className={`nav-el`} to={'/teachers'}>
                    <div onClick={()=>handleResponsiveNavbar()} className={`icon ${(location.pathname == '/teachers' || location.pathname == "/teacher_agenda" || location.pathname == "/availabilities" || location.pathname == "/admin_week_type") && 'active'}`}>
                        <span className="nav-icon collective_lessons"></span>
                        {i18n.t('navigation.admin.teachers')}
                    </div>
                </Link>

                    {/* sublink */}
                    { (location.pathname == '/teachers' || location.pathname == "/teacher_agenda" || location.pathname == "/availabilities" || location.pathname == "/admin_week_type") &&
                        <div className="sublink-bloc">
                            <Link className="nav-el" to={'/teachers'}>
                                <div onClick={()=>handleResponsiveNavbar()} className={`icon ${location.pathname == '/teachers' && 'active'}`}>
                                    {i18n.t('navigation.admin.list')}
                                </div>
                            </Link>
                            <Link className={`nav-el ${loading && 'disabled loading'}`} to={'/teacher_agenda'}>
                                <div onClick={()=>handleResponsiveNavbar()} className={`icon ${location.pathname == '/teacher_agenda' && 'active'}`}>
                                    {i18n.t('navigation.admin.agendas')}
                                </div>
                            </Link>
                            <Link className={`nav-el ${loading && 'disabled loading'}`} to={'/availabilities'}>
                                <div onClick={()=>handleResponsiveNavbar()} className={`icon ${location.pathname == '/availabilities' && 'active'}`}>
                                    {i18n.t('navigation.admin.availabilities')}
                                </div>
                            </Link>

                            <Link className={`nav-el ${loading && 'disabled loadiπng'}`} to={'/admin_week_type'}>
                                <div onClick={()=>handleResponsiveNavbar()} className={`icon ${location.pathname == '/admin_week_type' && 'active'}`}>
                                    {i18n.t('navigation.admin.week_type')}
                                </div>
                            </Link>
                        </div>
                    }

                <Link className={`nav-el`} to={'/tickets/availability'}>
                    <div onClick={()=>handleResponsiveNavbar()} className={`icon ${(location.pathname == '/tickets/recredit' || location.pathname == '/tickets/availability' || location.pathname == '/tickets/students')  && 'active'}`}>
                        <span className="nav-icon elearning"></span>
                        {i18n.t('navigation.admin.tickets')}
                    </div>
                </Link>

                    { (location.pathname == '/tickets/recredit' || location.pathname == '/tickets/availability' || location.pathname == '/tickets/students') &&
                      <div className="sublink-bloc">
                        <Link className="nav-el" to={'/tickets/recredit'}>
                          <div onClick={()=>handleResponsiveNavbar()} className={`icon ${location.pathname == '/tickets/recredit' ? 'active' : ''}`}>
                            {i18n.t('navigation.admin.recredits')}
                          </div>
                        </Link>
                        <Link className="nav-el" to={'/tickets/availability'}>
                          <div onClick={()=>handleResponsiveNavbar()} className={`icon ${location.pathname == '/tickets/availability' ? 'active' : ''}`}>
                          {i18n.t('navigation.admin.availabilities')}
                          </div>
                        </Link>
                        <Link className="nav-el" to={'/tickets/students'}>
                          <div onClick={()=>handleResponsiveNavbar()} className={`icon ${location.pathname == '/tickets/students' ? 'active' : ''}`}>
                            {i18n.t('navigation.admin.trainings')}
                          </div>
                        </Link>
                      </div>
                    }

        </div>
    )
}

export default StaffNav;
