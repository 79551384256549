import React, { useState, useEffect } from "react";
import SubStep from "./subStep";
import openArrow from "../../images/icons/openArrow.svg";
import closeArrow from "../../images/icons/closeArrow.svg";

export default function Steps({ setCurrentRow, node, index, openedNode, setOpenedNode,currentRow }) {

  const [color, setColor] = useState("black");

  const [openedSubStep, setOpenedSubStep] = useState([])

  const onClickOpened = (nodeId) => {
    setOpenedNode((prevState) => {
      if (prevState?.includes(nodeId)) {
        setOpenedSubStep([]);
        return prevState.filter((id) => id !== nodeId);
      }
      else {
        return [...prevState, nodeId];
      }
    });
  };



  useEffect(() => {
    const checkColorByAssignee = () => {
      let conditionYellow = node?.thematics?.some((item) =>
        item?.exercices?.some(
          (exercice) =>
            exercice?.assignment !== null && exercice?.state === "assign"
        )
      );

      let conditionGreen = node?.thematics?.some((item) =>
        item?.exercices?.some(
          (exercice) =>
            exercice?.assignment !== null && exercice?.state !== "done"
        )
      );

      if (conditionYellow) {
        return "yellow";
      } else if (conditionGreen) {
        return "green";
      } else if (conditionYellow && conditionGreen) {
        return "yellow";
      } else {
        return "black";
      }
    };

    setColor(checkColorByAssignee());
  }, [node]);

  return (
    <div className="steps-container">
      <li className="li">
        <div>


          <div className="step-header">
          {node.thematics && (
            <span onClick={() => onClickOpened(index)}>
              {openedNode?.includes(index) ? (
                <img className="icon" src={openArrow} />
              ) : (
                <img className="icon" src={closeArrow} />
              )}
            </span>
          )}
            <span onClick={() => onClickOpened(index)} className={`exercice-type ${color}`}>
              {node?.exercice_type}
            </span>
            <span onClick={() => onClickOpened(index)} className={`exercice-type-count ${color}`}
            >
              {node?.count_label}
            </span>
          </div>

        </div>
        {node?.thematics && openedNode?.includes(index) ? (
          <ul className="tree">
            {node.thematics.map((item, index) => (
              <SubStep key={index} setCurrentRow={setCurrentRow} index={index} data={item} openedSubStep={openedSubStep} setOpenedSubStep={setOpenedSubStep} currentRow={currentRow} />
            ))}
          </ul>
        ) : ''}
      </li>
    </div>
  );
}
