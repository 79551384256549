import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18n from '../../../i18n'

const TeacherNav = ({ setShowNavbar, showNavbar, setOpenNavbar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state.user.userData)
  const dispatch = useDispatch()
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)


  const handleResponsiveNavbar = () => {
    if (responsiveOne ) {

      setShowNavbar(!showNavbar)
      dispatch(setOpenNavbar(!showNavbar))
    }

  }

  return (
    <div className="nav-list">

      <Link className="nav-el" to={'/t_planning'}>
        <div className={`icon ${(location.pathname == '/t_planning' || location.pathname == '/lessons') && 'active'}`}>
          <span className="nav-icon planning"></span>
          {i18n.t('navigation.teacher.lessons')}
        </div>
      </Link>

      {/* sublink */}
      {(location.pathname == '/lessons' || location.pathname == "/t_planning" || location.pathname == "/week_type") &&
        <div className="sublink-bloc">
          <Link className="nav-el" to={'/t_planning'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/t_planning' ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.agenda')}
            </div>
          </Link>
          <Link className="nav-el" to={'/lessons'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/lessons' ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.lessons')}
            </div>
          </Link>
          <Link className="nav-el" to={'/week_type'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/week_type' ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.week_type')}
            </div>
          </Link>
        </div>
      }

      <Link className={`nav-el`} to={'/trainings'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/trainings' && 'active'}`}>
          <span className="nav-icon students"></span>
          {i18n.t('navigation.teacher.trainings')}
        </div>
      </Link>


      <Link className={`nav-el`} to={'https://elearning.cercledeslangues.com/'} target="_href" >
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${(location.pathname === '/elearnings_card_view' || location.pathname === "/elearnings_card_view")
            ? 'active' : ''} `}>
          <span className="nav-icon elearning"></span>
          {i18n.t('navigation.student.elearnings')}
        </div>
      </Link>


      <Link className={`nav-el`} to={'/tickets/availability'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${(location.pathname == '/tickets/recredit' || location.pathname == '/tickets/availability' || location.pathname == '/tickets/students') && 'active'}`}>
          <span className="nav-icon elearning"></span>
          {i18n.t('navigation.teacher.tickets')}
        </div>
      </Link>

      {(location.pathname == '/tickets/recredit' || location.pathname == '/tickets/availability' || location.pathname == '/tickets/students') &&
        <div className="sublink-bloc">
          <Link className="nav-el" to={'/tickets/recredit'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/tickets/recredit' ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.recredits')}
            </div>
          </Link>
          <Link className="nav-el" to={'/tickets/availability'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/tickets/availability' ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.availabilities')}
            </div>
          </Link>
          <Link className="nav-el" to={'/tickets/students'}>
            <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/tickets/students' ? 'active' : ''}`}>
              {i18n.t('navigation.teacher.trainings')}
            </div>
          </Link>
        </div>
      }

      <Link className={`nav-el`} to={'/teacher'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/teacher' && 'active'}`}>
          <span className="nav-icon profile"></span>
          {i18n.t('navigation.teacher.profile')}
        </div>
      </Link>

      <Link className={`nav-el`} to={'/invoices'}>
        <div onClick={() => handleResponsiveNavbar()} className={`icon ${location.pathname == '/invoices' && 'active'}`}>
          <span className="nav-icon invoices"></span>
          {i18n.t('navigation.teacher.invoices')}
        </div>
      </Link>

    </div>
  )
}

export default TeacherNav;
