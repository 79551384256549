import React from "react";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const atasColumns = [
  {
    name: "id",
    selector: row => row.id,
    omit: true
  },
  {
    name: "Date annulation",
    selector: row => row.date,
    width:'15%'
  },
  {
    name: "Heure",
    selector: row => row.hour,
    width:'6%'

  },
  {
    name: "Formation",
    selector: row => row.training,
    width:'14%'
  },
  {
    name: "Professeur",
    selector: row => row.teacher,
  },
  {
    name: "Date de cours",
    selector: row => row.event_date,
  },
  {
    name: "Heure",
    selector: row => row.event_hours,
  },
  {
    name: "Statut du cours",
    selector: row => row.expected,
  },

  {
    name: "Justification",
    selector: row => {
      return (
        <div className="table-sub-list">
          <p style={{whiteSpace:'wrap'}}> {row?.note} </p>
        </div>
      )
    },
    width:'15%'

  },
  {
    name: "Document",
    selector: row => {
      return (
        <div className="table-sub-list">
          {
            row?.app_document ?
            <div className="list-item" > <a target="_blank" href={row?.app_document}> <FontAwesomeIcon className="icon-details" icon={faDownload} color="#AFAFAF" /> </a></div>
              : '-'
          }
        </div>
      )
    },
    width:'6%'


  },



];

export default atasColumns
