import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "./components/header";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import {setFiltersCoursCollectives} from "../filters/filtersSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchCourseCollectives, resetCourseCollective } from "./coursCollectivesSlice";
import CourseCollectiveCard from "./components/courseCollectiveCard";
import Modal from '../toolbox/modal'
import StateManagement from "../lessons/components/state_management";
import { fetchUser } from "../user/userSlice";



export default function CoursCollectives() {

  const filters = useSelector(state => state.filters.filtersCoursCollectives);
  const { store } = useContext(ReactReduxContext)
  const user = useSelector(state => state.user.userData);
  const location = useLocation();
  const navigate=useNavigate()
  const search = location.search;
  const ref = useRef(null);
  const modalState = new URLSearchParams(search).get('pm');
  const[controlModal, setControlModal] = useState({})
  const dt = new Date(); // current date of week
  const currentWeekDay = dt.getDay();
  const lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
  const wkStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
  const wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 7));
  const { id } = useSelector((state) => state.user.userData)
  const { courseCollectivelist, loading } = useSelector((state) => state.coursCollectives)
  const openNavbar = useSelector((state)=>state.navigations.openNavbar)
  const responsiveFilter = useSelector((state)=>state.navigations.openResponsiveFilters)
  const [page, setPage] = useState(1)
  const state = store.getState();
  const dispatch = useDispatch()
  const path = location.pathname

  useEffect(() => {
    setTimeout(() => {
        dispatch(fetchUser())
    }, 1000)
    
  }, [search]);

  useEffect(() => {
    if ((user.role)) {
          dispatch(setFiltersCoursCollectives({ scope: "all_lessons" }))
    }
  }, [user, path]);

  useEffect(() => {
    if (modalState == 'm') {
      ref?.current?.onShow()
    } else {
      ref?.current?.onHide()
    }
  }, [modalState]);

  const beSubscribe = () => {
      navigate(`?l=${controlModal?.item?.id}&pm=m&state=course_collective_booking`);
  }

  useEffect(()=>{
    if(controlModal?.item){
      beSubscribe()
    }
  },[controlModal])


  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersCoursCollectives
    if (page && !loading) {
      dispatch(fetchCourseCollectives({ filters, page }))
    }
  }, [page, filters]);


  return (
    <div className= {`main-content`}>
        <div className= {`page-container ${(openNavbar||responsiveFilter) && 'responsive-hidden'}`}>
        <Header />
        <div className="course-collective-list-container">
          {
            courseCollectivelist?.map((item, index) => {
              return (
                <div key={item?.id} className="course-collective-item">
                  <CourseCollectiveCard item={item} index={index} setControlModal={setControlModal} />
                </div>
              )
            })
          }
        </div>



      </div>


      <Modal ref={ref}>
        <StateManagement />
      </ Modal>


    </div>
  )
}
