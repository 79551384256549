import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OverlayTrigger, Popover } from "react-bootstrap"
import React from "react"
import {
 faCircle
} from '@fortawesome/free-solid-svg-icons';

export default function RenderIcon({status}){

  let circleColor
      switch(status) {
        case "Réalisé":
          circleColor = "#1abc9c"
          break
        case "Planifié":
          circleColor = "#FFC400"
          break
        case "Non réalisé":
          circleColor = "#cc8574"
          break
        case "Aucun":
          circleColor = "grey"
          break
      }

  return(
    <div>
       <OverlayTrigger trigger={["hover"]}
          overlay={
            <Popover id="popover-basic" className='exercice-actions-popin'>
              <Popover.Content>
                <div>{status}</div>
              </Popover.Content>
            </Popover>
          }
        >
          <FontAwesomeIcon icon={faCircle} color={circleColor} size="lg" pull="left" />
      </OverlayTrigger>
    </div>
  )
}
