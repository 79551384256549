
import React from "react"
import assigneeBtnGreen from '../../images/icons/assigneBtn-green.svg'
import unassigneBtnGreen from '../../images/icons/unassignebtn-green.svg'
import unassigneBtnGrey from '../../images/icons/unassignebtn-grey.svg'
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { assigneExercise, fetchExercises } from "../elearningSlice"
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import i18n from "../../../i18n";


export default function AssigneButton({ id, sided, state, assigne, canUnassign }) {
  const search = useLocation().search;
  const trainingId = new URLSearchParams(search).get('t');
  const lessonId = new URLSearchParams(search).get('l') || location.pathname.split("/")[2];
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.filters.filtersELearning)
  const user = useSelector((state) => state.user.userData)

  const onClick = (text) => {
    let expectedState = ""

    if (text === 'assigne') {
      expectedState = "assign"

      if (state === undefined) {
        expectedState
      }

      dispatch(assigneExercise({ exercice_id: id, student_id: user?.id, training_id: trainingId, state: expectedState, lesson_id: lessonId }))
      //dispatch(fetchExercises(filters))

    }
    else {
      expectedState = 'remove'
      dispatch(assigneExercise({ exercice_id: id, student_id: user?.id, training_id: trainingId, state: expectedState, lesson_id: lessonId }))
      //dispatch(fetchExercises(filters))
    }


  }

  return (
    <>
      {assigne === null ? (
        <button
          className="icon-button-elearning"
          onClick={() => onClick('assigne')}
        >
          <img className={`icon ${sided}`} src={assigneeBtnGreen} alt="Assign" />
        </button>
      ) : canUnassign ? (
        <button
          className="icon-button-elearning"
          onClick={() => onClick('unassigne')}
        >
          <img className={`icon ${sided}`} src={unassigneBtnGreen} alt="Unassign" />
        </button>
      ) : (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id={`tooltip-disabled`}>{i18n.t('exercices.unassign_text')}</Tooltip>}
        >
          <span>
            <button
              className="icon-button-elearning"
              disabled
            >
              <img className={`icon ${sided}`}
                   style={{cursor: 'not-allowed'}}
                   src={unassigneBtnGrey} alt="Unassign" />
            </button>
          </span>
        </OverlayTrigger>
      )}
    </>
  );
}
