import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Filters from "../../filters/filters";
import CreditCount from "./credit_count";
import ActiveFilters from "../../filters/activeFilters";
import i18n from "../../../i18n";
import DownloadReview from './downloadReview'

const Header = ({ lessonId, status }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname
  const user = useSelector(state => state.user.userData)
  const hasFilter = (!path.includes("/planning") && !path.includes("/t_planning"))
  const planningPath = user.role == "Teacher" ? '/t_planning' : '/planning'

  const title = () => {
    if (path === "/lessons" && (user.role == "Teacher" || user.role == "Admin")) {
      return `${i18n.t('navigation.teacher.lessons')}`
    }
    if (path === "/lessons") {
      return `${i18n.t('navigation.student.lessons')}`
    }
    if (path === '/planning' || path === '/t_planning') {
      return `${i18n.t('navigation.teacher.agenda')}`
    }
    if (path === "/collective_lessons") {
      return `${i18n.t('navigation.student.collective_lessons')}`
    }
    if (path.includes("/teacher_agenda")) {
      return `${i18n.t('navigation.admin.teacher_agenda')}`
    }
    if(path === "/admin_week_type"){
      return `${i18n.t('navigation.admin.week_type')}`
    }
  }


  const renderActiveFilters = () => {
    return hasFilter &&
      <ActiveFilters model={"filtersLesson"} action={"setFiltersLesson"} />
  }

    const renderFilters = () => {
        return hasFilter &&
        <Filters model={ path.includes("collective_lessons") ? "Collectives" : "Lessons"}/>
    }

    if (path.includes("/teacher_agenda") || path.includes("/admin_week_type")  ) {
      return (
        <>
          <div className="main-header">
            <div className="main-title">
              <h2>{title()}</h2>
            </div>
            <div className="main-actions">
              <div className="actions">
              </div>
              <Filters model={"TeacherAgenda"} />
            </div>
          </div>
          <ActiveFilters model={"filtersLesson"} action={"setFiltersLesson"} />
        </>
      )
  }


  return (
    <>
      <div className="main-header">
        <div className="main-title">
          <h2>{title()}</h2>
          <CreditCount />
        </div>
        <div className="main-actions">
          <DownloadReview />
          {user?.role !== 'Admin' &&
            <div className="actions">
              <div className="list-switch">
                <Link className="switch-el" to={location.pathname.includes('collective') ? '/collective_lessons' : '/lessons'}>
                  <div className={`icon lessons ${location.pathname.includes('lessons') ? 'active' : ''}`}></div>
                </Link>
                <Link className="switch-el" to={location.pathname.includes('collective') ? '/collective_planning' : planningPath}>
                  <div className={`icon planning ${location.pathname == planningPath ? 'active' : ''}`}></div>
                </Link>
              </div>
            </div>
            }
          {renderFilters()}
        </div>
      </div>
      {renderActiveFilters()}
    </>
  )

};

export default Header;
