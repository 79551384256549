import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import i18n from '../../i18n'


const TeacherProfile = ({ calendar }) => {
  const teacher = useSelector(state => state.teachers.teacherShow)
  const responsive = useSelector((state)=> state.navigations.responsiveOne)
  const user = useSelector(state => state.user.userData)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="container-teacher">
      <h3 className="title-fullname"> {teacher?.first_name} {teacher?.last_name} </h3>
      <span className="title"> <a href={teacher?.whereby_link} target="_href" > {i18n.t('activerecord.attributes.teacher.virtual_class')} </a>  </span>
      <span className="title"> {user.role == 'Admin' && teacher?.french_level} </span>
      <span className="title"> {user.role == 'Admin' && teacher?.professional_competences?.join(', ')} </span>

      <span className="title"> {teacher?.phone_number} </span>
      <span className="title">{teacher.email}</span>

      <iframe style={{marginTop:'10px'}} width={responsive ? windowWidth-60 :560} height={310} src={teacher?.video} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen >  </iframe>


      <p style={{ marginTop: '10px' }}> <span className="presentation">
      {i18n.t('activerecord.attributes.teacher.professional_presentation')}:</span>  {teacher?.professional_presentation} </p>
    </div>
  )


};

export default TeacherProfile;
