import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import {OverlayTrigger, Popover} from 'react-bootstrap';
import i18n from '../../../i18n'


const InvoiceTable = () => {
    const invoice = useSelector(state => state.invoices.invoiceShow)

    const renderPopover = (
        <Popover id='button-Popover' className="info-popover">
            {i18n.t('views.invoices.notice.cancel')}
        </Popover>
    )

    const renderReviewPopover = (
        <Popover id='button-Popover' className="info-popover">
            <p>{i18n.t("views.invoices.notice.review")}</p>


        </Popover>
    )

    const renderInvoiceData = (
        invoice.data_invoice.map((i, idx) => {

            return (
                <tr key={idx}>
                    <td className="text">
                      {`${i18n.t(`views.invoices.rate_type.cp_day`)}`}

                      </td>
                    <td>{i[8]}</td>
                    <td className="multi-td important">
                        <div><span className="entete">{i18n.t('views.invoices.done')} :</span><span className="value">{i[0]}</span></div>
                        <div><span className="entete">{i18n.t('views.invoices.late_cancelation')} :</span><span className="value">{i[1]}</span></div>
                        <div><span className="entete">{i18n.t('views.invoices.noshow')} :</span><span className="value">{i[2]}</span></div>
                    </td>
                    <td>{i[6]}</td>
                </tr>
            )
        })
    )

        return (
            <div className={`invoice-table`}>
                <table>
                    <thead>
                        <tr>
                            <th>{i18n.t('views.invoices.lessons')}</th>
                            <th>{i18n.t('views.invoices.rate')}</th>
                            <th># {i18n.t('views.invoices.accountable')}
                                <OverlayTrigger
                                    trigger="click"
                                    placement="bottom"
                                    containerPadding={20}
                                    rootClose
                                    overlay={renderReviewPopover}
                                    key={invoice.id}
                                    delay={{ show: 250, hide: 400 }}
                                >
                                    <span>
                                        <FontAwesomeIcon className="icon-details" icon={faInfoCircle} color="#1ABC9C" />
                                    </span>
                                </OverlayTrigger>
                            </th>
                            <th># {i18n.t('views.invoices.unaccountable')}
                                <OverlayTrigger
                                    trigger="click"
                                    placement="bottom"
                                    containerPadding={20}
                                    rootClose
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderPopover}
                                >
                                    <span>
                                        <FontAwesomeIcon className="icon-details" icon={faInfoCircle} color="#1ABC9C" />
                                    </span>
                                </OverlayTrigger>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderInvoiceData}
                    </tbody>
                </table>
            </div>
        )

};

export default InvoiceTable;
