import React from "react";
import TableDemandAction from "./tableDemandAction";
import CommentTooltip from "../components/commentTooltip";
import i18n from "../../../i18n";
import TableCancelAction from "./tableCancelAction";

const studentColumns = [
  {
    name: "id",
    selector: row => row.id,
    omit: true
  },
  {
    name: i18n.t('activerecord.attributes.tickets.created_at'),
    selector: row => row?.date,
    width: '15%'
  },

  {
    name: i18n.t('activerecord.attributes.tickets.manager'),
    selector: row => row.student_manager,
    width: '10%'
  },
  {
    name: i18n.t('activerecord.attributes.tickets.training'),
    selector: row => row.training,
    width: '10%'
  },
  {
    name: i18n.t('activerecord.attributes.tickets.ticket_type'),
    selector: row => {
      return (
        <div className={`general-icon small-medium ${row.expected} `}>
        </div>
      )
    },
    width: '10%'
  },
  {
    name: i18n.t('activerecord.attributes.tickets.teacher_note'),
    selector: row => (
      <div >
        <CommentTooltip note={row.note} />
      </div>
    ),
    width: '15%'
  },
  {
    name: i18n.t('activerecord.attributes.tickets.manager_note'),
    selector: row => (
      <div style={{ marginLeft: '10px' }}>
        <CommentTooltip note={row.admin_note} />
      </div>

    ),

    width: '20%'
  },
  {
    name: i18n.t('activerecord.attributes.tickets.cancel_button'),
    selector: row => {
      return (
        <div className="table-sub-list">
        { (row?.state !== 'refused' && row?.state !== 'validated') &&  <TableCancelAction data={row?.canceled} id={row.id} />}
        </div>
      )
    },
     width: '10%'
  },

  {
    name: i18n.t('activerecord.attributes.tickets.status'),
    selector: row => {
      return (
        <TableDemandAction data={row?.state} id={row.id} />
      )
    },
    width: '10%'
  },



];

export default studentColumns
