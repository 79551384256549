import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (thunkAPI) => {
    try {
      const response = await axios.get('/api/v1/users', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: {}
  },
  reducers: {
    setUserData: () => {
      state.userData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.userData = action.payload
      //state.isSubscribed = action.payload.isSubscribed
    })
  },
})

export const userData = createSelector(
  (state) => ({
    userData: state.userData,
    //isSubscribed: state.isSubscribed,
  }),
  (state) => state.userData
)

export const { setUserData } = userSlice.actions
export default userSlice.reducer
