import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCalendarLessons } from "./lessonsSlice";
import { fetchUser } from '../user/userSlice';
import { fetchCalendarAvailabilities } from "../availabilities/availabilitiesSlice";
import { setFiltersLesson } from "../filters/filtersSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from './components/header';
import Calendar from "../toolbox/calendar";
import Avatar from "../teachers/avatar";
import Modal from "../toolbox/modal";
import StateManagement from "./components/state_management";
import { faDoorClosed } from "@fortawesome/free-solid-svg-icons";
import Event from "./components/admin_event";
import TrainingManagement from "../trainings/components/trainingManagement";

//column rendering depending of role / sided

const Agenda = () => {
  const dt = new Date();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [start_at, setStartAt] = useState(null)
  const [end_at, setEndAt] = useState(null)
  const user = useSelector(state => state.user.userData)
  const lessons = useSelector(state => state.lessons.lessonsCal)
  const availabilities = useSelector(state => state.availabilities.availabilitiesCal)
  const location = useLocation();
  const search = location.search;
  const ref = useRef(null);
  const modalState = new URLSearchParams(search).get('pm');
  let filters = useSelector(state => state.filters.filtersLesson);
  const teacherId = new URLSearchParams(search).get('teacher_id');
  const teacherName = new URLSearchParams(search).get('teacher_name');
  const teacherFromParams = { label: teacherName, value: teacherId }
  const path = location.pathname
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveFilter = useSelector((state) => state.navigations.openResponsiveFilters)



  useEffect(() => {
    if (teacherId) {
      dispatch(setFiltersLesson({ teachers: teacherFromParams }))
    }
  }, [path]);

  useEffect(() => {
    if (filters.teachers) {
      let teachers = filters.teachers
      dispatch(fetchCalendarLessons({ teachers, start_at, end_at }))
    }
  }, [start_at, filters]);

  useEffect(() => {
    if (filters.teachers) {
      let teachers = filters.teachers
      dispatch(fetchCalendarAvailabilities({ teachers, start_at, end_at }))
      dispatch(fetchUser())
    }
  }, [lessons])

  useEffect(() => {
    if (modalState == 'm') {
      ref.current?.onShow()
    } else {
      ref.current?.onHide()
    }
  }, [modalState]);

  const changeDate = (date) => {
    const temp_wkStart = new Date(new Date(date).setDate(new Date(date).getDate()));
    const temp_wkEnd = new Date(new Date(temp_wkStart).setDate(temp_wkStart.getDate() + 7));
    setEndAt(temp_wkEnd)
    setStartAt(temp_wkStart)
  }

  const handleSelect = (newEvent) => {

    navigate(`?a=${JSON.stringify(newEvent)}&pm=m&state=admin_new_av`)
  }

  const closeAdditional = () => {
    if (filters.teachers) {
      let teachers = filters.teachers
      dispatch(fetchCalendarLessons({ teachers, start_at, end_at }))
    }
  }



  return (


    <div className="main-content">
      <div className={`page-container ${(openNavbar || responsiveFilter) && 'responsive-hidden'}`} >
        <Header adminAgenda />
        {(filters.teachers?.label && location.pathname == "/teacher_agenda") && <Avatar calendar />}
        <Calendar event={Event} data={lessons} secondaryData={availabilities} date={dt} changeDate={changeDate} selectable={filters.teachers} handleSelect={handleSelect} />
      </div>
      {location?.search?.includes('ts') || location?.search?.includes('tv') ?
        <Modal ref={ref} closeAdditional={closeAdditional}>
          <TrainingManagement />

        </Modal> :
        <Modal ref={ref} closeAdditional={closeAdditional}>

          <StateManagement />

        </Modal>
      }


    </div>
  )

};

export default Agenda;
