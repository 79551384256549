import React from "react";
import Status from "../components/status";
import Issue from '../components/issue';
import i18n from "../../../i18n";
import RenderIcon from "../components/renderIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClipboardCheck, faClipboard
} from '@fortawesome/free-solid-svg-icons';
import UpsellButton from "../components/upsellBtn";
import OpenTraining from '../components/openTraining';
import Impersonate from "../../user/impersonate";


const columns = [
  {
    name: "id",
    selector: row => row.id,
    omit: true
  },
  {
    name: `${i18n.t('views.trainings.alert')}`,
    width: "10%",
    selector: row => <Issue has_issue={row.ticket_issue} note={row.ticket_issue_note} id={row.id} />

  },
  {
    name: `${i18n.t('views.trainings.chart.upsell')}`,
    selector: row => <UpsellButton trainingId={row.id} ticket_upsell={row?.ticket_upsell} note={row.ticket_upsell_note} />,
    width: "9%"

  },
  {
    name: `${i18n.t('views.trainings.training')}`,
    width: "10%",
    selector: row => <OpenTraining row={row}><div className="bold">{row.student}</div></OpenTraining>
  },
  {
    name: `${i18n.t('views.trainings.card.labels.teacher')}`,
    width: "10%",
    selector: row => {
      return (

        <div className="label">
          <OpenTraining row={row}><div>{row.teacher}</div></OpenTraining>

        </div>

      )
    }
  },
  {
    name: `${i18n.t('views.trainings.dates')}`,
    width: "10%",
    selector: row => {
      return (
        <div className="table-sub-list">

          <OpenTraining row={row}><div>
            <div className="list-item"><div className="label">DEBUT :</div> {row.start_at}</div>
            <div className="list-item"><div className="label">FIN :</div> {row.end_at}</div>            </div></OpenTraining>



        </div>
      )
    },
  },

  {
    name: `${i18n.t('views.trainings.status')}`,
    width: "8%",
    selector: row => {
      return (
        <div style={{ zIndex: '-10' }} className={`table-sub-list ${row.student_status}`}>
          <OpenTraining row={row}><div>
            {i18n.t(`activerecord.attributes.student.status.${row.student_status}`)}
          </div></OpenTraining>

        </div>
      );
    }
  },

  {
    name: `${i18n.t('views.trainings.progress')}`,
    width: "10%",
    selector: row => {
      return (
        <div style={{ zIndex: '100' }} className="table-sub-list">
          <OpenTraining row={row}><div>
            <div className="list-item"><div className="label">CP : </div>{row.lessons_done}</div>
            <div className="list-item"><div className="label">CC : </div>{row.cc_lessons_done}</div>
            <div className="list-item"><div className="label">Elearning : </div>{row.elearning}</div></div></OpenTraining>

        </div>
      )
    }
  },
  {
    name: `${i18n.t('views.trainings.lessons')}`,
    width: "15%",
    selector: row => {
      return (
        <div className="table-sub-list">
          <OpenTraining row={row}><div>
            <div className="list-item"><div className="label">Dernier : </div>{row.last_lesson_date}</div>
            <div className="list-item"><div className="label">prochain : </div>{row.next_lesson_date}</div>
            <div className="list-item">
              <div className="label">Carnet : </div>
              {(row.has_passed_lessons) ?
                row.review_done ? (
                  <a className="list-link" href={row.show_link}>
                    <FontAwesomeIcon icon={faClipboardCheck} color="#1abc9c" size="lg" pull="left" />
                  </a>
                ) : (
                  <a className="list-link" href={row.show_link}>
                    <FontAwesomeIcon icon={faClipboard} color="#cc8574" size="lg" pull="left" />
                  </a>
                ) : ''}
            </div>
          </div></OpenTraining>

        </div>
      )
    }
  },
  {
    name: `${i18n.t('views.trainings.test.title')}`,
    width: "10%",
    selector: row => {
      return (
        <div className="table-sub-list">
          <OpenTraining row={row}><div >
            <div className="list-item"><div className="label">Type : </div><div className="value">{row.test_type}</div></div>
            <div className="list-item"><div className="label">Date : </div><div className="value">{row.planned_test_date}</div></div>
            <div className="list-item"><div className="label">Statut : </div><div
              className="value">
              <RenderIcon status={row.evaluation_status} />

            </div>
            </div>
          </div></OpenTraining>
        </div>


      )
    }
  },
  {
    name: "",
    width: "8%",
    selector: row => <Impersonate user_id={row.user_id} />,
  }
];

export default columns
